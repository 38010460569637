import axios from 'axios';
import VueAxios from 'vue-axios';
import {
  Form, Field, ErrorMessage, defineRule, configure,
} from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import zhTW from '@vee-validate/i18n/dist/locale/zh_TW.json';
import { createApp } from 'vue';
import { createPinia } from 'pinia'
import Loding from '@/components/LodIng.vue';
import App from './App.vue';
import router from './router';
import $httpMessage from './methods/pushMessage';
import $emitter from './methods/emitter';
import { currency, date } from './methods/filters';
import 'bootstrap-icons/font/bootstrap-icons.css';

import CustomPageIndexView from './views/CustomPageView.vue';
import CustomPageDetailView from './views/CustomPageView/CustomPageView.vue';

import VueGtag from 'vue-gtag';

console.log('test - 59');

axios.defaults.baseURL = "https://www.nxin.com.tw/api"
// axios.defaults.baseURL = "https://www.ddf.com.tw/api"
// axios.defaults.baseURL = "https://web2.dentalhsieh.com/api"
// axios.defaults.baseURL = "https://nxin2.dentalhsieh.com/api"

axios.get(`/ga/measurement/id`).then((result) => {

  const measurement_id = result.data.id
  // console.log('measurement_id', measurement_id)

  Object.keys(AllRules).forEach((rule) => {
    defineRule(rule, AllRules[rule]);
  });
  configure({
    generateMessage: localize({ zh_TW: zhTW }), // 載入繁體中文語系
    validateOnInput: true, // 當輸入任何內容直接進行驗證
  });
  setLocale('zh_TW');

  if (result.data.custom_page !== '' && result.data.custom_page !== 'custom_page') {
    console.log('custom_page test...123')
    router.addRoute('home', {
      path: '/' + result.data.custom_page,
      component: CustomPageIndexView,
      children: [
        {
          path: ':title',
          meta: {
            page_name: '自訂頁面',
            eng_page_name: 'custom_page',
          },
          component: CustomPageDetailView,
        },
      ],
    });
  }

  router.beforeEach((to, from, next) => {
    // window.alert('網站正在維護中...');

    let url = '/system/seo/' + to.meta.eng_page_name;

    if (to.meta.detail_page === '1') {
      url += '?id=' + to.params.id
    }

    axios.get(url).then((res) => {
      console.log('--- test 1111 ---')
      document.title = res.data.title
      document.querySelector('meta[name="keywords"]').content = res.data.keyword;
      document.querySelector('meta[name="description"]').content = res.data.desc;
      next();
    }, () => {
      console.log('--- test 22222 ---')
      next();
    });
  });

  const pinia = createPinia()
  const app = createApp(App);

  app.config.globalProperties.$custom_color = result.data.color
  app.config.globalProperties.$custom_text = result.data.text
  app.config.globalProperties.$website_url = result.data.url
  app.config.globalProperties.$website_nav = result.data.nav

  app.use(pinia)
  app.use(VueAxios, axios);
  app.use(router);
  app.use(VueGtag, {
    pageTrackerTemplate(to) {
      console.log(to.meta.page_name);
      return {
        page_title: to.meta.page_name,
        page_path: to.path
      }
    },
    config: {
      id: measurement_id,
    },
  }, router)
  app.config.globalProperties.$filters = {
    currency,
    date,
  };
  app.component('LoadIng', Loding);
  app.config.globalProperties.$httpMessage = $httpMessage;
  app.config.globalProperties.$emitter = $emitter;
  app.component('ForM', Form);
  app.component('FielD', Field);
  app.component('ErrorMessage', ErrorMessage);
  app.mount('#app');

});
